import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Spinner,
  Stack,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import Airtable from "airtable";
import "firebase/analytics";
import publicIp from "public-ip";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import "./App.css";

function App({ logo, baseID, terms }) {
  let date = new Date();
  let formatted_date = date.toLocaleDateString("en-US");
  const time = date.toLocaleTimeString("en-US");

  // Setup Media Variables
  const boxWidth = useBreakpointValue({ base: "95%", md: "md" });
  const boxHeight = useBreakpointValue({ base: "60%", md: "400" });

  Airtable.configure({
    endpointUrl: "https://api.airtable.com",
    apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  });

  var base = Airtable.base(baseID);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { isSubmitting, isSubmitSuccessful, isDirty, isValid, errors },
  } = useForm({
    defaultValues: {
      Date: formatted_date,
      Time: time,
      consent: true,
      "Influencer Interest": false,
    },
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    register("IP");
    publicIp
      .v4({
        fallbackUrls: ["https://ifconfig.co/ip"],
      })
      .then((data) => {
        setValue("IP", data);
      });
  }, [register, setValue]);

  const onSubmit = (data) => {
    base("UGC Approvals").create(data, function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
    });
  };

  if (!baseID)
    return (
      <VStack
        spacing={8}
        align='center'
        justify='center'
        className='App'
        bg='brand'
      >
        <Center>
          <Spinner color='brand' />
        </Center>
      </VStack>
    );

  return (
    <VStack
      spacing={8}
      align='center'
      justify='center'
      className='App'
      bg='brand'
    >
      <Box
        w={boxWidth}
        minH={boxHeight}
        borderWidth='1px'
        borderRadius='lg'
        overflow='hidden'
        boxShadow='md'
        mx='auto'
        p={50}
        bg='white'
      >
        <Container h='100%'>
          <Stack spacing={4} justify='space-between' h='100%'>
            {logo ? (
              <Image src={logo} alt='Dogswell' maxH={100} />
            ) : (
              <Center>
                <Spinner color='brand' />
              </Center>
            )}
            {isSubmitSuccessful ? (
              <Center h='100%'>
                <Heading>Thank you!</Heading>
              </Center>
            ) : (
              <>
                <Stack spacing={4}>
                  <FormControl id='Handle'>
                    <InputGroup>
                      <InputLeftAddon children='@' />
                      <Input
                        isRequired
                        placeholder='Handle'
                        variant='filled'
                        isInvalid={errors?.Handle}
                        name='Handle'
                        {...register("Handle", {
                          required: "Please enter a Handle",
                          minLength: {
                            value: 3,
                            message: "Minimum length should be 4",
                          },
                        })}
                      />
                      <FormErrorMessage color='red'>
                        {errors?.Handle?.message}
                      </FormErrorMessage>
                    </InputGroup>
                  </FormControl>

                  <FormControl id='Email'>
                    <Input
                      type='email'
                      placeholder='Email Address'
                      variant='filled'
                      name='Email'
                      {...register("Email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email address",
                        },
                      })}
                    />
                    <FormHelperText color={errors?.Email ? "red" : "gray"}>
                      {errors?.Email
                        ? errors?.Email?.message
                        : "Optional, We'll never share your Email."}
                    </FormHelperText>
                  </FormControl>

                  <Controller
                    name='consent'
                    control={control}
                    defaultValue=''
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Checkbox
                        size='md'
                        colorScheme='white'
                        iconColor='brand'
                        isRequired
                        isInvalid={errors?.consent}
                        {...field}
                        defaultIsChecked
                      >
                        Do you agree with the{" "}
                        <Link href={terms} target='_blank'>
                          Terms & Conditions?
                        </Link>
                      </Checkbox>
                    )}
                  />
                  <Controller
                    name='Influencer Interest'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Checkbox
                        size='md'
                        colorScheme='white'
                        iconColor='brand'
                        isInvalid={errors?.consent}
                        {...field}
                      >
                        Interested in becoming an influencer? Get on our waiting
                        list.
                      </Checkbox>
                    )}
                  />
                </Stack>
                <Button
                  bg='brand'
                  size='lg'
                  color='white'
                  isLoading={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                  isDisabled={!isDirty && !isValid}
                >
                  I Agree
                </Button>
              </>
            )}
          </Stack>
        </Container>
      </Box>
    </VStack>
  );
}

export default App;
