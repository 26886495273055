import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { HeadProvider, Link, Meta, Title } from "react-head";
import App from "./App";
import BBLogo from "./assets/BBlogo.svg";
import CSlogo from "./assets/CSlogo.svg";
import TLogo from "./assets/tikilogo.svg";
import reportWebVitals from "./reportWebVitals";

const currentURL = window.location.href;
let siteVars;
if (currentURL.includes("dogswell"))
  siteVars = {
    title: "Dogswell UGC",
    logo: "https://www.dogswell.com/wp-content/uploads/2021/04/dw-logo-1.svg",
    siteIcon:
      "https://www.dogswell.com/wp-content/uploads/2021/04/Dogswell_White_Icon-1.png",
    brand: "#5566DA",
    baseID: "appovn6UdjXfVOJjF",
    terms: "https://www.dogswell.com/terms-conditions/",
    tc: "",
  };
if (currentURL.includes("buddybiscuits"))
  siteVars = {
    title: "Buddy Biscuits UGC",
    logo: BBLogo,
    siteIcon:
      "https://buddybiscuits.com/wp-content/uploads/2019/02/bb-logo@2x.png",
    brand: "#FF4D00",
    baseID: "appUYLl5Ggy26A7wA",
    terms: "https://www.buddybiscuits.com/terms-conditions/",
    tc: "",
  };
if (currentURL.includes("cloudstar"))
  siteVars = {
    title: "Cloud Star UGC",
    logo: CSlogo,
    siteIcon:
      "https://www.cloudstar.com/wp-content/uploads/2015/05/cropped-logo.jpg",
    brand: "#EE2D24",
    baseID: "appCPh0Q9EAcN7XEa",
    terms: "https://www.cloudstar.com/terms-conditions/",
    tc: "",
  };
if (currentURL.includes("localhost") || currentURL.includes("tikipets"))
  siteVars = {
    title: "Tiki Pets UGC",
    logo: TLogo,
    siteIcon:
      "https://tikipets.com/wp-content/uploads/2017/10/cropped-Favicon1.png",
    brand: "#06C49F",
    baseID: "appHEtu1KYq1T6hOn",
    terms: "https://www.tikipets.com/terms-conditions/",
    tc: "",
  };

if (siteVars) {
  ReactGA.initialize(siteVars?.tc);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const colors = {
  brand: siteVars?.brand,
};
const override = {
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "brand",
        color: "white",
      },
      // styles for the `a`
      a: {
        color: "brand",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },
};
const theme = extendTheme({ colors, override });

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <HeadProvider>
        <Title>{siteVars?.title}</Title>
        <Meta charSet='utf-8' />
        <Link rel='icon' href={siteVars?.siteIcon} />
        <Meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta name='robots' content='noindex' />
        <Link rel='apple-touch-icon' href={siteVars?.siteIcon} />
        <Title>{siteVars?.title}</Title>
      </HeadProvider>
      <App {...siteVars} />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
